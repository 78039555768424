<template>
  <QLayout view="hHh lpR fFf">
    <MainHeader />
    <QAjaxBar
      v-if="hasLoadingBar"
      ref="loadingBar"
      position="top"
      color="secondary"
      size="3px"
    />

    <QPageContainer>
      <router-view v-slot="{ Component }">
        <transition
          enter-active-class="animated fadeIn"
          leave-active-class="animated fadeOut"
          appear
          :duration="300"
        >
          <component :is="Component" />
        </transition>
      </router-view>
    </QPageContainer>

    <AppFooter
      v-if="$route.meta.hideFooter !== true"
      class="q-pa-xl"
    />
    <CookiePopup />
  </QLayout>
</template>

<script>
import AppFooter from 'components/nav/Footer'
import MainHeader from 'components/nav/MainHeader'
import CookiePopup from 'hc-core/components/popups/cookie-popup'

export default {
  components: { MainHeader, AppFooter, CookiePopup },
  data () {
    return {
      routeTransitionName: '',
      checkoutOpenedDialog: false,
      hasLoadingBar: false,
      loadingBarRouteGuard: _ => _,
    }
  },
  async mounted () {
    // Don’t show AjaxBar before first navigation for consistency
    this.loadingBarRouteGuard = this.$router.afterEach(this.showLoadingBar)
  },
  methods: {
    showLoadingBar () {
      this.hasLoadingBar = true
      this.loadingBarRouteGuard() // unregister
    }
  },
}
</script>
